import {css} from '@emotion/core';
import {faCog, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormikHelpers} from 'formik';
import {graphql, useStaticQuery} from 'gatsby';
import {Fragment, useCallback, useContext, useMemo, useRef} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {AjaxResponse} from 'rxjs/ajax';
import {MIN_TABLET_MEDIA_QUERY} from 'typography-breakpoint-constants';
import {ModalsContext} from '../../contexts/ModalsContext';
import {colors} from '../../styles/colors';
import {rhythm0_25, rhythm0_5, rhythm1, rhythm12, rhythm1_5, rhythm2} from '../../styles/typography';
import {font150} from '../../styles/typography-node';
import {ajaxObservable} from '../../utils/ajax';
import {useFormikSubmitter} from '../../utils/react/useFormikSubmitter';
import {trackCtaFormSubmitted} from '../../utils/tracking/trackCtaForm';
import {buttonCss, PrimaryButton} from '../Button';
import {CheckboxFormField, FormField} from '../FormField';
import {AjvGlobalErrors} from '../GlobalErrors';
import {Link} from '../Link';
import {SafeText, SafeTextHandler} from '../Safe';

if (!process.env.CTA_URL) {
  throw new Error(`${__filename}: Missing process.env.CTA_URL`);
}

const messages = defineMessages({
  emailLabel: {id: 'components.Cta.emailLabel', defaultMessage: 'Email'},
  emailPlaceholder: {id: 'components.Cta.emailPlaceholder', defaultMessage: 'Type your email here'},
  messageLabel: {id: 'components.Cta.messageLabel', defaultMessage: 'Message'},
  messagePlaceholder: {id: 'components.Cta.messagePlaceholder', defaultMessage: 'Type your message here'},
  acceptPrivacyLabel: {id: 'components.Cta.acceptPrivacyLabel', defaultMessage: 'I have read the <privacy-policy-link>privacy policy</privacy-policy-link> and I accept how you use the data you collect from this form.'},
  submitButton: {id: 'components.Cta.submitButton', defaultMessage: 'Contact me now'},
  processingButton: {id: 'components.Cta.processingButton', defaultMessage: 'Processing…'},
  acceptPrivacyValidationError: {id: 'components.Cta.acceptPrivacyValidationError', defaultMessage: 'You must accept the <privacy-policy-link>privacy policy</privacy-policy-link> to continue.'},
  successfulMessage: {id: 'components.Cta.successfulMessage', defaultMessage: 'Thank you. I\'ll get back to you as soon as possible.'},
});

const ctaCss = css({
  margin: `${rhythm1} 0`,
  // textAlign: 'center',
});

const ctaCenterAlwaysCss = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const childrenCss = css({
  ...font150,
});

const childrenCenterAlwaysCss = css({
  ...font150,
  textAlign: 'center',
});

const formCss = css({
  display: ['-ms-grid', 'grid'],
  gap: rhythm0_5,

  [MIN_TABLET_MEDIA_QUERY]: {
    display: ['-ms-grid', 'inline-grid'],
    gridTemplateColumns: 'min-content min-content',
  },
});

const formFieldCss = css({
  width: ['80%', `calc(100% - ${rhythm2})`],
  margin: '0 auto',
  // padding: `0 ${typography.rhythm(1)}`,

  '& + &': {
    marginTop: rhythm0_5,
  },

  [MIN_TABLET_MEDIA_QUERY]: {
    width: `${rhythm12}`,
    padding: 0,
  },
});

const inputsCss = css({
  [MIN_TABLET_MEDIA_QUERY]: {
    gridColumnStart: 1,
    gridRowStart: 1,
  },
});

const buttonsCss = css({
  gridRowStart: 3,
  padding: `0 ${rhythm1}`,
  whiteSpace: 'nowrap',

  [MIN_TABLET_MEDIA_QUERY]: {
    gridRowStart: 1,
    gridColumnStart: 2,
    padding: 0,
  },
});

const acceptPrivacyCss = css({
  gridRowStart: 2,

  [MIN_TABLET_MEDIA_QUERY]: {
    width: 'auto',
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
});

const errorsCss = css({
  gridRowStart: 4,

  [MIN_TABLET_MEDIA_QUERY]: {
    gridRowStart: 3,

    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
});

const globalErrorsCss = css({
  textShadow: 'none',
});

// const recaptchaCss = css({
//   fontSize: `${font60.fontSize} !important`,
//   lineHeight: `${font60.lineHeight} !important`,
// });

const callMeCss = css(buttonCss, {
  marginLeft: rhythm0_5,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',

  color: `${colors.buttons.success.text} !important`,
  borderColor: colors.buttons.success.border,
  backgroundColor: colors.buttons.success.background,
  width: rhythm1_5,
  height: rhythm1_5,
  padding: 0,

  '&:hover': {
    backgroundColor: colors.buttons.success.focus.background,
    borderColor: colors.buttons.success.focus.border,
  },

  '&:focus': {
    backgroundColor: colors.buttons.success.focus.background,
    borderColor: colors.buttons.success.focus.border,
  },

  '&:active': {
    backgroundColor: colors.buttons.success.active.background,
    borderColor: colors.buttons.success.active.border,
  },

  '&[disabled]': {
    backgroundColor: colors.buttons.success.disabled.background,
    borderColor: colors.buttons.success.disabled.border,

    cursor: 'not-allowed',
  },
});

const spamInputCss = css({
  display: 'none',
});

const submittingCss = css({
  cursor: 'progress',
});

const renderAcceptPrivacyError = () => (
  <FormattedMessage
    {...messages.acceptPrivacyValidationError}
    values={{
      'privacy-policy-link': (children: React.ReactNode) => (
        <Link href="privacy-policy">{children}</Link>
      ),
    }}
  />
);

type Data = {
  locale: string,
  // recaptcha: string,
  acceptPrivacy: boolean,
  cta: {
    email: string,
    message?: string,
  },
};

type Props = {
  center?: 'always' | 'mobile',
  children?: React.ReactNode,
};

export const Cta = (props: Props) => {
  const {center, children} = props;

  const {business} = useStaticQuery(query);

  const {formatMessage, locale} = useIntl();

  // const recaptchaHandlerRef = useRef<RecaptchaHandler>(null);

  const initialValues: Data = useMemo(
    () => ({
      locale: '',
      // recaptcha: '',
      acceptPrivacy: false,
      cta: {
        email: '',
        message: '',
      },
    }),
    [],
  );

  // const submit = useCallback(
  //   (data: Data) => from(recaptchaHandlerRef.current?.getToken('cta') || empty())
  //       .pipe(
  //         switchMap((recaptcha) => ajaxObservable({
  //           url: process.env.CTA_URL!,
  //           method: 'POST',
  //           body: {...data, locale, recaptcha},
  //           timeout: 10000,
  //         }))
  //       ),
  //   [locale],
  // );

  const submit = useCallback(
    (data: Data) => ajaxObservable({
      url: process.env.CTA_URL!,
      method: 'POST',
      body: {...data, locale},
      timeout: 10000,
    }),
    [locale],
  );

  const {notify} = useContext(ModalsContext);

  const onSubmit = useCallback(
    (result: AjaxResponse, helpers: FormikHelpers<Data>) => {
      trackCtaFormSubmitted();

      notify({
        body: (
          <FormattedMessage {...messages.successfulMessage} />
        ),
      });

      helpers.resetForm();
    },
    [notify],
  );

  const {handleSubmit, handleChange, values, isSubmitting, getErrors} = useFormikSubmitter({
    initialValues,
    submit,
    onSubmit,
  });

  const submitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const spamElement = e.currentTarget.elements.namedItem('address') as HTMLInputElement;

      if (spamElement.value) {
        e.preventDefault();
        return;
      }

      handleSubmit(e);
    },
    [handleSubmit],
  );

  const safePhoneHandlerRef = useRef<SafeTextHandler>();

  const decodeSafePhone = useCallback(
    () => { safePhoneHandlerRef.current?.decode(); },
    [],
  );

  return (
    <div
      css={[
        ctaCss,
        isSubmitting && submittingCss,
        center === 'always' && ctaCenterAlwaysCss,
      ]}
    >
      {children && (
        <div
          css={[
            childrenCss,
            center === 'always' && childrenCenterAlwaysCss,
          ]}
        >
          {children}
        </div>
      )}

      <form css={formCss} onSubmit={submitForm}>
        <input css={spamInputCss} aria-hidden="true" type="text" name="address" autoComplete="off" />

        <div css={inputsCss}>
          <FormField id="CtaEmail" css={formFieldCss} label={formatMessage(messages.emailLabel)} showLabel={false} errors={getErrors('cta.email')}>
            <input required type="email" name="cta.email" placeholder={formatMessage(messages.emailPlaceholder)} value={values.cta.email} onChange={handleChange} autoComplete="email" />
          </FormField>

          <FormField id="CtaMessage" css={formFieldCss} label={formatMessage(messages.messageLabel)} showLabel={false} errors={getErrors('cta.message')}>
            <textarea rows={2} name="cta.message" placeholder={formatMessage(messages.messagePlaceholder)} value={values.cta.message} onChange={handleChange} autoComplete="off" />
          </FormField>
        </div>

        <CheckboxFormField css={[formFieldCss, acceptPrivacyCss]} errors={getErrors('acceptPrivacy')} renderError={renderAcceptPrivacyError}>
          <input required id="ContactFormAcceptPrivacy" type="checkbox" name="acceptPrivacy" checked={values.acceptPrivacy} onChange={handleChange} />

          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="ContactFormAcceptPrivacy">
            <FormattedMessage
              {...messages.acceptPrivacyLabel}
              values={{
                'privacy-policy-link': (children: React.ReactNode) => (
                  <Link href="privacy-policy">
                    {children}
                  </Link>
                ),
              }}
            />
          </label>
        </CheckboxFormField>

        <div css={buttonsCss}>
          <PrimaryButton type="submit" disabled={isSubmitting}>
            {!isSubmitting && (
              <FormattedMessage {...messages.submitButton} />
            )}

            {isSubmitting && (
              <Fragment>
                <FontAwesomeIcon icon={faCog} spin fixedWidth />
                {' '}
                <FormattedMessage {...messages.processingButton} />
              </Fragment>
            )}
          </PrimaryButton>

          <SafeText lazy text={business.phoneSafe} ref={safePhoneHandlerRef}>
            {(text) => (
              <Link css={callMeCss} href={text ? `tel:${text}` : ''} onMouseEnter={decodeSafePhone}>
                <FontAwesomeIcon icon={faPhone} fixedWidth />
              </Link>
            )}
          </SafeText>
        </div>

        <div css={errorsCss}>
          <AjvGlobalErrors
            css={globalErrorsCss}
            errors={[
              ...getErrors(''),
              ...getErrors('cta'),
              ...getErrors('locale'),
              // ...getErrors('recaptcha'),
            ]}
          />

          {/* <Recaptcha ref={recaptchaHandlerRef} css={recaptchaCss} /> */}
        </div>
      </form>
    </div>
  );
};

Cta.whyDidYouRender = true;

const query = graphql`
  query {
    business: settingsYaml(settings: {eq: "business"}) {
      phoneSafe
    }
  }
`;
