
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'
import {Cta} from 'components/Cta'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Responsive web design, tradotto con “progettazione web responsiva”, è una tecnica di progettazione per far adattare il sito web alle dimensioni dello schermo, in modo automatico.`}</p>
    <p>{`Puoi vederne gli effetti con i tuoi occhi ruotando il tuo telefono, o ridimensionando la finestra del browser; vedrai come il mio sito si adatti perfettamente alle dimensioni del display.`}</p>
    <h2>{`Come funziona il responsive web design?`}</h2>
    <p>{`L’aspetto di un sito web è definito da una serie di regole contenute in un file di testo chiamato CSS. Nel responsive web design, queste regole vengono suddivise in gruppi, i quali si attivano in base alle dimensioni del browser.`}</p>
    <p>{`Per esempio, possiamo comandare al browser di visualizzare due paragrafi in due colonne affiancate nel caso in cui la larghezza della pagina sia maggiore di un determinato valore; un paragrafo sotto l’altro, invece, nel caso in cui la larghezza sia minore di quel valore.`}</p>
    <p>{`Questo ci permette di ottimizzare l’aspetto del sito web, rendendolo leggibile e confortevole nella  fruizione da tutti i dispositivi che decidiamo di supportare; solitamente ottimizziamo il sito per smartphone, tablet, PC con schermo piccolo e PC con schermo grande.`}</p>
    <p>{`Il funzionamento è quello che si vede nel seguente estratto di codice. Applicando l’approccio `}<a parentName="p" {...{
        "href": "mobile-first-web-design"
      }}>{`mobile first`}</a>{` al responsive web design, comandiamo al browser di visualizzare un titolo in rosso sui dispositivi con uno schermo largo più di 1024px, in blu su tutti gli altri:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-css"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/* Questa regola vale per tutti i dispositivi. */`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-selector-class"
        }}>{`.titolo`}</span>{` {
  `}<span parentName="code" {...{
          "className": "hljs-attribute"
        }}>{`color`}</span>{`: blue;
}

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`@media`}</span>{` (`}<span parentName="code" {...{
          "className": "hljs-attribute"
        }}>{`min-width:`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`1024px`}</span>{`) {
  `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/* Questa, più specifica, vale per i dispositivi
     con uno schermo largo almeno 1024 pixel. */`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-selector-class"
        }}>{`.titolo`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-attribute"
        }}>{`color`}</span>{`: red;
  }
}
`}</code></pre>
    <h2>{`Che vantaggi ha il responsive web design?`}</h2>
    <p>{`Un sito web responsivo è accessibile e confortevole da navigare, perché è progettato per essere visualizzato su display di ogni dimensione. Chi accede al tuo sito potrà trovare le informazioni che cerca in modo semplice ed efficace.`}</p>
    <p>{`Grazie al responsive web design non dovremo gestire contemporaneamente versioni diverse del sito (per esempio, www`}{`.`}{`example`}{`.`}{`com per i PC e mobile`}{`.`}{`example`}{`.`}{`com per i telefoni cellulari). Infatti, averne un’unica versione che si adatta automaticamente, piuttosto che una per ogni tipo di dispositivo, ne riduce i tempi, i costi e le difficoltà di manutenzione.`}</p>
    <Cta center={"always"} mdxType="Cta">
      <p>{`Vuoi realizzare il tuo sito web con i benefici del responsive web design?`}</p>
    </Cta>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    